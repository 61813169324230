import React from 'react';
//import images
import Image1 from '../img/about/1.jpg'
import Image2 from '../img/about/2.jpg'
import Image3 from '../img/about/3.jpg'
import Image4 from '../img/about/4.jpg'
import Image5 from '../img/about/5.jpg'
import Image6 from '../img/about/6.jpg'


import Video1 from '../img/about/vid-insta.MP4'
import Video2 from '../img/about/vid-face.MOV'
//import link
import { Link } from 'react-router-dom';
//import motion
import {motion} from 'framer-motion';
//import transition
import {transition1} from '../transitions';
//variant
import { fadeIn } from '../variants';

 // import translation react-i18nxt
 import {useTranslation } from "react-i18next"


const About = () => {
   const [t, i18n] = useTranslation("global");

  return ( 
   <div className='overflow-x-hidden'>
      <motion.section 
         initial={{ opacity: 0, y: '100%'}}
         animate={{opacity: 1, y: 0}}
         exit={{opacity: 0, y: '100%'}}
         transition={transition1}
         className='section'
      >
         <div className="container mx-auto h-full relative">
            <div className='flex flex-col lg:flex-row h-full items-center justify-start gap-x-32  lg:text-left pt-24 lg:pt-36 pb-8'>
               {/* text */}
               <motion.div
               initial={{ opacity: 0, y: '-80%'}}
               animate={{opacity: 1, y: 0}}
               exit={{opacity: 0, y: '-80%'}}
               transition={transition1}
               className='flex flex-col lg:items-start '>
                  <h1 className='h1 pl-2 pt-5'>{t("about.section1.heading")}</h1>
                  <p className='mb-6 m-3 max-w-md '>
                     {t("about.section1.description")} <br/>
                     <br/>
                     {t("about.section1.servicesdescription")} <br/>
                     {t("about.section1.services.0")}<br/>
                     {t("about.section1.services.1")}<br/>
                     {t("about.section1.services.2")}<br/>
                     {t("about.section1.services.3")}<br/>
                     {t("about.section1.services.4")}<br/>
                     {t("about.section1.services.5")}<br/>
                     {t("about.section1.services.6")}<br/>
                     <br/>
                     {t("about.section1.question")}
                  </p>
                  <Link to={'/contact'} className='btn-grad mb-[30px] mx-auto lg:mx-0'>
                     {t("about.section1.contactButton")}
                  </Link>
               </motion.div>
               {/* image grid */}
               <div 
               className='grid grid-cols-2 sm:grid-cols-3 gap-2 lg:gap-1 '>
                  {/* image */}
                  {/* image1 */}
                     <div className='group relative overflow-hidden border-2 border-white/20 rounded-xl'>
                        {/* overlay */}
                        <div className='group-hover:bg-black/20 w-full h-full absolute z-40 transition-all duration-300'>
                        </div>
                        <div className='max-w-[250px] lg:w-[190px] xl:max-w-[400px] h-[187px] lg:h-[220px] xl:h-[280px] bg-accent overflow-hidden'>
                           <img className='object-cover min-w-[170px] h-full lg:h-[320px] xl:h-[280px] lg:group-hover:scale-125 translation-all duration-500' src={Image1} alt="" />
                        </div>
                     </div>
                  {/* image 2 */}
                  <div className='group relative overflow-hidden border-2 border-white/20 rounded-xl'>
                        {/* overlay */}
                        <div className='group-hover:bg-black/20 w-full h-full absolute z-40 transition-all duration-300'>
                        </div>
                        <div className='max-w-[250px] lg:w-[190px] xl:max-w-[400px] h-[187px] lg:h-[220px] xl:h-[280px] bg-accent overflow-hidden'>
                           <img className='object-cover min-w-[170px] h-full lg:h-[320px] xl:h-[280px] lg:group-hover:scale-125 translation-all duration-500' src={Image2} alt="" />
                        </div>
                     </div>
                  {/* image 3 */}
                  <div className='group relative overflow-hidden border-2 border-white/20 rounded-xl'>
                        {/* overlay */}
                        <div className='group-hover:bg-black/20 w-full h-full absolute z-40 transition-all duration-300'>
                        </div>
                        <div className='max-w-[250px] lg:w-[190px] xl:max-w-[400px] h-[187px] lg:h-[220px] xl:h-[280px] bg-accent overflow-hidden'>
                           <img className='object-cover min-w-[170px] h-full lg:h-[320px] xl:h-[280px] lg:group-hover:scale-125 translation-all duration-500' src={Image3} alt="" />
                        </div>
                     </div>
                  {/* image 4 */}
                  <div className='group relative overflow-hidden border-2 border-white/20 rounded-xl'>
                        {/* overlay */}
                        <div className='group-hover:bg-black/20 w-full h-full absolute z-40 transition-all duration-300'>
                        </div>
                        <div className='max-w-[250px] lg:w-[190px] xl:max-w-[400px] h-[187px] lg:h-[220px] xl:h-[280px] bg-accent overflow-hidden'>
                           <img className='object-cover min-w-[170px] h-full lg:h-[320px] xl:h-[280px] lg:group-hover:scale-125 translation-all duration-500' src={Image4} alt="" />
                        </div>
                     </div>
                  {/* image 5 */}
                  <div className='group relative overflow-hidden border-2 border-white/20 rounded-xl'>
                        {/* overlay */}
                        <div className='group-hover:bg-black/20 w-full h-full absolute z-40 transition-all duration-300'>
                        </div>
                        <div className='max-w-[250px] lg:w-[190px] xl:max-w-[400px] h-[187px] lg:h-[220px] xl:h-[280px] bg-accent overflow-hidden'>
                           <img className='object-cover min-w-[170px] h-full lg:h-[320px] xl:h-[280px] lg:group-hover:scale-125 translation-all duration-500' src={Image5} alt="" />
                        </div>
                     </div>
                  {/* image 6 */}
                  <div className='group relative overflow-hidden border-2 border-white/20 rounded-xl'>
                        {/* overlay */}
                        <div className='group-hover:bg-black/20 w-full h-full absolute z-40 transition-all duration-300'>
                        </div>
                        <div className='max-w-[250px] lg:w-[190px] xl:max-w-[400px] h-[187px] lg:h-[220px] xl:h-[280px] bg-accent overflow-hidden'>
                           <img className='object-cover min-w-[170px] h-full lg:h-[320px] xl:h-[280px] lg:group-hover:scale-125 translation-all duration-500' src={Image6} alt="" />
                        </div>
                     </div>
               </div>
            </div>
         </div>
      </motion.section>
      {/* Section 2 */}
      <section className='section '>
      <div className='container mx-auto h-full relative'>
         {/* text & img wrapper */}
         <div className='flex flex-col lg:flex-row h-full items-center justify-center gap-x-24 lg:gap-x-10 text-center lg:text-left lg:pt-16  '>

            
            {/* smartphone */}
            <motion.div
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            className='flex-1 order-2 lg:order-none overflow-hidden justify-center items-center'>
               <div className="smartphone-portrait  w-2/4">
                  <video playsInline autoPlay muted preload="auto" loop width="100%" height="100%">
                     <source src={Video1} type='video/mp4' />
                  </video>
                  <div className='relative lg:text-center top-6'>
                     <a className='text-grad-instagram' href="https://www.instagram.com/beauty_dog.il/" target='_blank'>{t("about.section2.link")}</a>
                  </div>
               </div>
            </motion.div>


            {/* text */}
            <motion.div
             variants={fadeIn('left', 0.5)}
             initial="hidden"
             whileInView={"show"}
             viewport={{once: false, amount: 0.3}}
             className='flex-1 pt-28 pb-4 lg:pt-0 lg:w-auto z-10 flex flex-col justify-center items-center lg:items-start'>
               <h1 className='h1'>Instagram:</h1>
               <p className='mb-12 max-w-sm'>
               <a className='text-grad-instagram' href='https://www.instagram.com/beauty_dog.il/' target='_blank'>@beauty_dog.il </a>
                  {t("about.section2.text")}
               </p>
            </motion.div>
         </div>
      </div>
    </section>
      {/* Section 3 */}
      <section className='section overflow-auto pb-20 lg:pb-28'>
      <div className='container mx-auto h-full relative'>
         {/* text & img wrapper */}
         <div className='flex flex-col lg:flex-row h-full items-center justify-center gap-x-24 lg:gap-x-10 text-center lg:text-left lg:pt-16  '>
            {/* text */}
            <motion.div
             variants={fadeIn('right', 0.3)}
             initial="hidden"
             whileInView={"show"}
             viewport={{once: false, amount: 0.3}}
             className='flex-1 pt-28 pb-4 lg:pt-0 lg:w-auto z-10 flex flex-col justify-center items-center lg:items-start'>
               <h1 className='h1'>Facebook:</h1>
               <p className='mb-12 max-w-sm'>
                  {t("about.section3.textbeforelink")}
                  <a className='text-grad-facebook pr-2 pl-2' href='https://www.facebook.com/profile.php?id=100087976549520' target='blank'>Beauty_dog.il</a>
                  {t("about.section3.textafterlink")}
               </p>
            </motion.div>
            {/* smartphone */}
            <motion.div
            variants={fadeIn('left', 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            className='flex-1 order-2 lg:order-none overflow-hidden'>
               <div className="smartphone-portrait w-2/4">
                  <video playsInline autoPlay muted preload="auto" loop>
                     <source src={Video2} type="video/mp4" />
                  </video>
                  <div className='relative lg:text-center top-6'>
                     <a className='text-grad-facebook' href='https://www.facebook.com/profile.php?id=100087976549520' target='_blank'>{t("about.section3.link")}</a>
                  </div>
               </div>
            </motion.div>
         </div>
      </div>
    </section>
   </div>
  );
};

export default About;
